<div class="pure-g-r">
  <div class="pure-u-4-5" id="intro">
    <p>Hi, my name is Peter. I am a web developer.</p>

    <p>I have passion for <em>Javascript</em> and writing powerful software with it. I especially enjoy working on front-end code, but working on the back-end is fun too.</p>

    <p>I am currently based in the Seattle/Tacoma area and seeking employment, you can contact me at {{{contact}}}.</p>
    <ul id="othersites">
    <li><i class="fa fa-github"></i> <a href="https://github.com/uselesscode/">Github</a></li>
    <li><i class="fa fa-stack-overflow"></i> <a href="http://stackoverflow.com/users/778975/useless-code">Stack Overflow</a></li>
    <li><i class="fa fa-stack-overflow"></i> <a href="http://careers.stackoverflow.com/peterjohnson">Careers 2.0</a></li>
    </ul>
  </div>
  <div class="pure-u-1-5">
    <p id="resume_link">My resume is available as a <a href="resume/resume_peter_johnson.pdf"> PDF</a> or the original <a href="resume/resume_peter_johnson.htm">HTML5</a>.</p>
  </div>
</div>
<script>
const miNombre = 'peter',
  at = "@",
  eml = `${miNombre + at}whendoiworknext.com`;
export default {
  data () {
    return {
      contact: `<a href="mailto:${eml}">${eml}</a>`,
    };
  },
};
</script>
