<div id="main">
  <header>
    <h2>Peter Johnson - Porfolio</h2>
  </header>
  <nav id="nav"><ul>
    <li><a href="#/aboutme">Intro</a></li>
    <li><a href="#/projects">Projects</a></li>
    <li><a href="#/aboutsite">About this app</a></li>
  </ul></nav>
  <div id="body">
    {{#if currentPath === 'aboutme'}}
      <Aboutme></Aboutme>
    {{elseif currentPath ==='projects'}}
    <Projects projects="{{projects}}" loadingError="{{loadingError}}" scrollTo="{{scrollTo}}"></Projects>
    {{elseif currentPath === 'aboutsite'}}
      <Aboutsite></Aboutsite>
    {{else}}
      <h1>404: Not found</h1>
    {{/if}}
  </div>
  <footer>
    <div id="techbadges">
      <i class="fa fa-html5"></i>
    </div>
  </footer>
</div>

<style>
nav {
  position: sticky;
  font-family: verdana;
  padding: .5em;
  margin: 0;
}

nav ul {
  margin: 0;
}

nav li {
  display: inline-block;
  margin-right: 1em;
}

nav li a {
  text-decoration: none;
  font-size: 1.5em;
  font-weight: bold;
  color: #aaa;
  /* color: #3af; */
  text-shadow: 1px 1px 1px #000;
}

nav li a:hover {
  text-shadow: 2px 2px 1px rgba(0, 0, 0, .75);
}

nav .active {
  color: #df9;
}
</style>

<script>
  import Aboutme from './Aboutme.svelte';
  import Projects from './Projects.svelte';
  import Aboutsite from './Aboutsite.svelte';
  //import options from '../common/options.js';

  export default {

    //oncreate () {
    //  this.interval = setInterval(() => {
    //    this.set({time: new Date()});
    //  }, 1000);

    //  window.oldRecentWindow = defaultOptions['recentWindow'];
    //  window.oldQuarterHour = -1;
    //},

    //ondestroy () {
    //  clearInterval( this.interval );
    //},

    //helpers: {
    //  fromNow,
    //},

    data () {
      return {
        projects: [],
      };
    },

    computed: {
      //hours: time => pad2(time.getHours()),
      //minutes: time => pad2(time.getMinutes()),
      //seconds: time => pad2(time.getSeconds()),
      //quarterHour: time => Math.floor(time.getMinutes() / 15),
      //bar: (foo, minutes) => `${foo}-${minutes}`,

    },

    components: {
      Aboutme,
      Projects,
      Aboutsite,
    }
  };
</script>
