<div class="pure-g-r">
  <div class="pure-u-4-5">

    {{#if loadingError}}
      <h3>
        Error loading project data.
      </h3>
    {{elseif projects.length === 0}}
      <h3>
          Loading data
      </h3>
    {{else}}
    <ul id="projectlist">
        {{#each filteredProjects as project}}
        <li>
          <div class="project" id="{{project.myAnchor}}">
            <h2 class="pheader">{{project.name}} <a
                href="#/projects?anchor={{project.myAnchor}}">&sect;</a></h2>
            {{#if project.img}}
            <!--
            <a href="{{project.img}}" class="img-link" on:click="showShadowbox(project.img)">
              <img src="{{project.img}}">
            </a>
            -->
              <img src="{{project.img}}" on:click="showShadowbox(project.img)">
            {{/if}}
            <p>
            {{{project.desc}}}
            </p>
            <ul class="tags">
              {{#each project.tags as tag}}
                <li on:click="showTag(tag)">{{tag}}</li>
              {{/each}}
            </ul>
          </div>
        </li>
        {{/each}}
    </ul>
    {{/if}}
  </div>
  <div class="pure-u-1-5">
    <div id="filters">
      <label for="ongoingtoggle" class="pure-checkbox"><input type="checkbox"
        bind:checked="showOngoing" id="ongoingtoggle">Ongoing projects</label><br>
      <label for="passedtoggle" class="pure-checkbox"><input type="checkbox"
        bind:checked="showPast" id="passedtoggle">Past projects</label>
      <div>
        <h3>Filter by tags</h3>
        {{#if projects}}
          <ul id="tagfilter" class="tags">
          {{#each tags as tag}}
          <li on:click='toggleTagByName(tag.name)' class="{{tag.checked ? '' : 'unchecked'}}">{{tag.name}}</li>
          {{/each}}
          </ul>
          <button class="pure-button" type="button" on:click="selectAll()">Select All</button>
          <button class="pure-button" type="button" on:click="selectNone()">Select None</button>
        {{/if}}
      </div>
    </div>
  </div>
</div>

<style>
  ul {
    list-style: none;
  }

  img {
    cursor: pointer;
  }

  #filters {
    position: sticky;
    top: 1em;
  }

  button {
    margin: .25em;
  }

.shadowbg {
    background: rgba(0,0,0, .8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.75s;
    z-index: 1000;
}

.shadowbg img {
    cursor: default;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100%;
    transition: height 1s, width 1s, top 1s, left 1s;
}
.shadowbg.hidden {
  opacity: 0;
}

.pheader a {
  text-decoration: none;
  font-size: 0.75em;
  color: #ccf;
  display: none;
}

.pheader:hover a {
  display: inline;
}

.pheader a:hover {
  color: #aac;
}



</style>


<script>

const setTags = function (filter) {
  let tags = this.get('tags');
  tags = tags.map(tag => {
     tag.checked = typeof filter === 'function' ? filter(tag) : filter;
     return tag;
   });
  this.set({tags});
};

export default {
  data () {
    return {
      projects: [],
      tags: [],
      showOngoing: true,
      showPast: true,
    };
  },

  oncreate () {
    const tagObserver = this.observe('projects', projects => {
      let tags = projects.reduce((tags, current) => {
        current.tags.forEach(tag => {
          if(-1 === tags.indexOf(tag)) {
            tags.push(tag);
          }
        });
        return tags;
      }, []);

      tags.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
      tags = tags.map(tag => {return {name: tag, checked: true};});
      this.set({tags});
    });
    const scrollObserver = this.observe('scrollTo', scrollTo => {
      if (scrollTo) {
        let el = document.getElementById(scrollTo);
        if (el) {
          el.scrollIntoView();
        }
        //requestAnimationFrame(() => {
        //  //location.hash = '#/projects';
        //  history.replaceState({}, '', '#/projects');
        //});
      }
    });
  },

  methods: {
    toggleTagByName: function (name) {
      let tags = this.get('tags');
      let index = tags.findIndex(item => item.name === name);

      tags[index].checked = !tags[index].checked;
      this.set({tags});
     },
     showShadowbox: function (url) {
      let bg = tq(`<div class="shadowbg hidden"><img src="${url}"></div>`);
      bg.bind('click', function (evt) {
        bg.remove('.shadowbg');
      });
      tq('#projectlist').after(bg);
      requestAnimationFrame(() => {
        bg.removeClass('hidden');
      });
     },
     showTag: function (targetTag) {
       setTags.call(this, tag => tag.name === targetTag);
     },
     selectAll: function () {
       setTags.call(this, true);
     },
     selectNone: function () {
       setTags.call(this, false);
     },
   },

  computed: {

    selectedTagNames: tags => {
      return tags.filter(tag => tag.checked).map(tag => tag.name);
    },
    filteredProjects: function (projects, showOngoing, showPast, selectedTagNames) {
      let showBoth = showOngoing && showPast,
        filtered = projects.filter(project => {
          let keep = 0;

          // filter past/ongoing
          if (project.isOngoing && showOngoing) {
            keep += 1;
          } else if (!project.isOngoing && showPast) {
            keep += 1;
          }

          return keep > 0;
        });

      filtered = filtered.filter(project => {
        let keep = 0
        project.tags.forEach(tag => {
          if (selectedTagNames.indexOf(tag) > -1) {
            keep += 1;
          }
        });
        return keep > 0;
      });

      return filtered;
    },
  }
};
</script>
